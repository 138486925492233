<script src="js/flexible.js"></script>
<script src="js/jquery.js"></script>
<script src="js/echarts.min.js"></script>
<script src="js/index.js"></script>
<script src="js/china.js"></script>
<script src="js/myMap.js"></script>

<template>
  <body class="bodytest">
    <div style="width: 100%; padding: 0.3rem 0.3rem; background-color: rgb(213, 234, 247); color: rgb(100, 170, 211);text-align: center; font-size: 0.9rem;" >咨询或有任何疑问回到小程序咨询</div>

    <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/f34f36f150c647e4ad0d171153474305.png" style="width: 70%; margin-left: 15%;">

    <div style=" margin: 0rem 0.5rem; margin-bottom: 0.5rem;">

      <div style="display: flex;">
        <div @click.stop="toDetailPage(1)" style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/e1bcec33372741f392a84e12eb2a44a1.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <div v-if="lastDataDetails.weightColorshow==0" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">{{lastDataDetails.weightDataShow}}</div>
            <div v-if="lastDataDetails.weightColorshow==2" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">{{lastDataDetails.weightDataShow}}</div>
            <div v-if="lastDataDetails.weightColorshow==1||lastDataDetails.weightColorshow==3" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:hsl(0, 100%, 55%);">{{lastDataDetails.weightDataShow}}</div>
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">{{lastDataDetails.weightColorshow==2}}</div> -->
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">体质指数</div>
          </div>
        </div>
        <div @click.stop="toDetailPage(2)" style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/d640df40136c45908b6e9a819d897035.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div v-if="lastDataDetails.btColorshow==0" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">{{lastDataDetails.btDataShow}}</div>
            <div v-if="lastDataDetails.btColorshow==2" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">{{lastDataDetails.btDataShow}}</div>
            <div v-if="lastDataDetails.btColorshow==1||lastDataDetails.btColorshow==3" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:hsl(0, 100%, 55%);">{{lastDataDetails.btDataShow}}</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">体温</div>
          </div>
        </div>
      </div>

      <div style="display: flex;">
        <div @click.stop="toDetailPage(3)" style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/ce6d47faa87246a0934aa7215cc5e4f1.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div v-if="lastDataDetails.heartRateColorshow==0" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">{{lastDataDetails.heartRateDataShow}}</div>
            <div v-if="lastDataDetails.heartRateColorshow==2" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">{{lastDataDetails.heartRateDataShow}}</div>
            <div v-if="lastDataDetails.heartRateColorshow==1||lastDataDetails.heartRateColorshow==3" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:hsl(0, 100%, 55%);">{{lastDataDetails.heartRateDataShow}}</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">心率</div>
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">{{lastDataDetails}}</div> -->
          </div>
        </div>
        <div @click.stop="toDetailPage(4)" style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/d660c41b03674d3ea904f80c1ea371bd.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div v-if="lastDataDetails.breathValueColorshow==0" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">{{lastDataDetails.breathValueDataShow}}</div>
            <div v-if="lastDataDetails.breathValueColorshow==2" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">{{lastDataDetails.breathValueDataShow}}</div>
            <div v-if="lastDataDetails.breathValueColorshow==1||lastDataDetails.breathValueColorshow==3" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:hsl(0, 100%, 55%);">{{lastDataDetails.breathValueDataShow}}</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">呼吸</div>
          </div>
        </div>
      </div>

      <div style="display: flex;">
        <div @click.stop="toDetailPage(5)" style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/69fbebe6a10142b3a57bf6897de13ec2.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div v-if="lastDataDetails.boValueColorshow==0" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">{{lastDataDetails.boValueDataShow}}</div>
            <div v-if="lastDataDetails.boValueColorshow==2" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">{{lastDataDetails.boValueDataShow}}</div>
            <div v-if="lastDataDetails.boValueColorshow==1||lastDataDetails.boValueColorshow==3" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:hsl(0, 100%, 55%);">{{lastDataDetails.boValueDataShow}}</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">血氧</div>
          </div>
        </div>
        <div @click.stop="toDetailPage(6)" style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/a4459d90267044cebb0d885fc3675978.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div v-if="lastDataDetails.bpColorshow==0" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">{{lastDataDetails.bpDataShow}}</div>
            <div v-if="lastDataDetails.bpColorshow==2" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">{{lastDataDetails.bpDataShow}}</div>
            <div v-if="lastDataDetails.bpColorshow==1||lastDataDetails.bpColorshow==3" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:hsl(0, 100%, 55%);">{{lastDataDetails.bpDataShow}}</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">血压</div>
          </div>
        </div>
      </div>

      <div style="display: flex;">
        <div @click.stop="toDetailPage(7)" style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/1f786b74e2ae46779f26dc4007be9867.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div v-if="lastDataDetails.bsValueColorshow==0" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">{{ lastDataDetails.bsValueDataShow }}</div>
            <div v-if="lastDataDetails.bsValueColorshow==2" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">{{lastDataDetails.bsValueDataShow}}</div>
            <div v-if="lastDataDetails.bsValueColorshow==1||lastDataDetails.bsValueColorshow==3" style="margin: 0.5rem 0rem; font-size: 0.8rem; color:hsl(0, 100%, 55%);">{{lastDataDetails.bsValueDataShow}}</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">血糖</div>
          </div>
        </div>
        <div style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/72a3b2fe46be44929537a80e340d0f22.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">无数据</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">尿酸</div>
          </div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/47f123c15a904645969e914b0ba28043.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">无数据</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">胆固醇</div>
          </div>
        </div>
        <div style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/328f97b40399451a99fd88fa729b82f5.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">无数据</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">心电图</div>
          </div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/3022354240654f108711466c76f17e3e.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">无数据</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">睡眠</div>
          </div>
        </div>
        <div style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/121f38cd0f8d44bf80047be51c43f96f.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">无数据</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">情绪压力</div>
          </div>
        </div>
      </div>

      <div style="display: flex;">
        <div style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/316ef2472b54420b88d3341c921efaca.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">无数据</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">运动</div>
          </div>
        </div>
        <div style="flex: 1;  border-radius: 1rem; border: 0.1rem solid #24a4fa; display: flex;  margin: 0.5rem 0.5rem; height: 4.5rem;">
          <img src="https://quanyouxiang.oss-cn-shanghai.aliyuncs.com/cmk/2023-05-11/6830cec092e9408bb73997548fc1a173.png" style="width: 3.5rem; height: 3.5rem; margin: 0.4rem 0.5rem;">
          <div style=" margin:0.4rem 0rem; text-align: center; width: 5rem;">
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">达标</div> -->
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:black;">无数据</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">中医体质</div>
            <!-- <div style="margin: 0.5rem 0rem; font-size: 0.8rem; color:#1fe22f;">{{wxUserId}}</div>
            <div style="margin: 0.5rem 0rem; font-size: 0.8rem; font-weight: 550;">{{idCard}}</div> -->
          </div>
        </div>
      </div>

    </div>

    <div style="position: relative;">
      <img src="https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2024-11-29/15f848703ee3384de378631329fd35280982.png" style="width: 92%; margin-left: 4%;">
      <div style="position: absolute; top: 15%; left: 10%;color: white;font-weight: 600; font-size: 1.3rem;">高血压病达标评估</div>
      <!-- <img src="https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2024-11-29/a41f06c2de9b82459a78be87a2e39473ec87.png" style="position: absolute; bottom: 30%; left: 10%; width: 10rem;"> -->
      <div style="position: absolute; bottom: 10%; left: 10%;color: white;font-weight: 600;">暂无健康风险</div>
      <!-- <div style="position: absolute; bottom: 10%; left: 10%;color: red;font-weight: 600;">存在健康风险</div> -->
    </div>

  </body>

</template>

<script>
import { getDate, userInfo, recentBreath } from '../../api/login'
export default {
  data () {
    return {
      requestHeadUrl: 'https://dbadmin.dbzdjk.com',
      imgurl: 'https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2023-10-25/1a70eab03b75c84e9b7913b89ab291444704.jpg',
      count: 0,
      loading: false,
      scrolltimer: '',
      channel: window.localStorage.getItem('channel'),
      list:[],
      numberDetails: {},
      punchTheClock: {},
      dataAbnormalLog: '',
      sum: 0,
      lastDataDetails: {
        weightColorshow: 0,
        btColorshow: 0,
        heartRateColorshow: 0,
        breathValueColorshow: 0,
        boValueColorshow: 0,
        bpColorshow: 0,
        bsValueColorshow: 0
      }
    }
  },
  created () {
    // this.wxUserId = this.$route.query.wxUserId
    // this.idCard = this.$route.query.idCard
    // // 直接读取全局变量
    // const wxUserId = window.__APP_CONFIG__?.wxUserId;
    // // 直接读取全局变量
    // const idCard = window.__APP_CONFIG__?.idCard;
    // this.$set('wxUserId', this.$route.query.wxUserId)
    // this.$set('idCard', this.$route.query.idCard)
    this.wxUserIdData = window.localStorage.getItem('wxUserIdData')
    this.idCard = window.localStorage.getItem('idCard')
    // console.log('test localStorage111')
    // console.log(this.wxUserIdData = window.localStorage.getItem('wxUserIdData'))
    // console.log(this.idCard = window.localStorage.getItem('idCard'))
    const thisObj = this
    thisObj.onLoad()
  },
  mounted() {
  },
  methods: {
    onLoad: function () {
      // console.log('test wxUserId', this.wxUserId)
      // console.log('test idCard', this.idCard)
      this.wxUserIdData = window.localStorage.getItem('wxUserIdData')
      this.idCard = window.localStorage.getItem('idCard')
      // console.log('test localStorage222')
      // console.log(this.wxUserIdData = window.localStorage.getItem('wxUserIdData'))
      // console.log(this.idCard = window.localStorage.getItem('idCard'))
      let item = {}
      item.wxUserIdData = this.wxUserIdData
      item.idCard = this.idCard
      console.log('test item', item)
      recentBreath(item).then(res => {
        const data = res.data
        // console.log('test recentBreath')
        // console.log(data)
        if(data.breathValue != null){
          if(data.breathValue){
            let breathValue = data.breathValue
            // this.lastDataDetails.breathValue = breathValue
            this.$set(this.lastDataDetails, 'breathValue', breathValue)
            if(breathValue < 12){
              // this.lastDataDetails.breathValueDataShow = '未达标'
              // this.lastDataDetails.breathValueColorshow = 1
              this.$set(this.lastDataDetails, 'breathValueDataShow', '未达标')
              this.$set(this.lastDataDetails, 'breathValueColorshow', 1)
            }else if(breathValue > 20){
              // this.lastDataDetails.breathValueDataShow = '未达标'
              // this.lastDataDetails.breathValueColorshow = 3
              this.$set(this.lastDataDetails, 'breathValueDataShow', '未达标')
              this.$set(this.lastDataDetails, 'breathValueColorshow', 3)
            }else{
              // this.lastDataDetails.breathValueDataShow = '达标'
              // this.lastDataDetails.breathValueColorshow = 2
              this.$set(this.lastDataDetails, 'breathValueDataShow', '达标')
              this.$set(this.lastDataDetails, 'breathValueColorshow', 2)
            }
          }
        }else{
          this.lastDataDetails.breathValueDataShow = '无数据'
          this.lastDataDetails.breathValueColorshow = 0
        }
      })
      userInfo(item).then(res => {
        const data = res.data
        // console.log('test userInfo')
        // console.log(data)
        this.userInfo = data
        // this.$set(this.userInfo, 'userInfo', data)
        // console.log(this.userInfo)
        // console.log(this.userInfo == '用户暂未绑定身份证或身份证错误' || this.userInfo == '用户认证失败')
      })
      getDate(item).then(res => {
        const data = res.data
        // console.log('test getDate')
        // console.log(data)
        // 体质指数
        if(data.weight != null && this.userInfo.height != null){
          if(data.weight){
            let BMI1 = data.weight * 100 * 100 / this.userInfo.height / this.userInfo.height
            this.lastDataDetails.weightBMI = BMI1.toFixed(2)
            this.lastDataDetails.weight = data.weight
            this.lastDataDetails.height = this.userInfo.height
            if(BMI1 < 18.5){
              // this.lastDataDetails.weightDataShow = '未达标'
              // this.lastDataDetails.weightColorshow = 1
              this.$set(this.lastDataDetails, 'weightDataShow', '未达标')
              this.$set(this.lastDataDetails, 'weightColorshow', 1)
            }else if(BMI1 > 24){
              // this.lastDataDetails.weightDataShow = '未达标'
              // this.lastDataDetails.weightColorshow = 3
              this.$set(this.lastDataDetails, 'weightDataShow', '未达标')
              this.$set(this.lastDataDetails, 'weightColorshow', 3)
            }else{
              // this.lastDataDetails.weightDataShow = '达标'
              // this.lastDataDetails.weightColorshow = 2
              this.$set(this.lastDataDetails, 'weightDataShow', '达标')
              this.$set(this.lastDataDetails, 'weightColorshow', 2)
            }
            console.log('BMI', BMI1.toFixed(2))
          }
        }else{
          // this.lastDataDetails.weightDataShow = '无数据'
          // this.lastDataDetails.weightColorshow = 0
          this.$set(this.lastDataDetails, 'weightDataShow', '无数据')
          this.$set(this.lastDataDetails, 'weightColorshow', 0)
        }
        // console.log('test lastDataDetails',this.lastDataDetails)

        //体温
        if(data.weight != null){
          if(data.btValue){
            let btValue = data.btValue
            this.lastDataDetails.btValue = btValue
            if(btValue < 36){
              // this.lastDataDetails.btDataShow = '未达标'
              // this.lastDataDetails.btColorshow = 1
              this.$set(this.lastDataDetails, 'btDataShow', '未达标')
              this.$set(this.lastDataDetails, 'btColorshow', 1)
            }else if(btValue > 37.3){
              // this.lastDataDetails.btDataShow = '未达标'
              // this.lastDataDetails.btColorshow = 3
              this.$set(this.lastDataDetails, 'btDataShow', '未达标')
              this.$set(this.lastDataDetails, 'btColorshow', 3)
            }else{
              // this.lastDataDetails.btDataShow = '达标'
              // this.lastDataDetails.btColorshow = 2
              this.$set(this.lastDataDetails, 'btDataShow', '达标')
              this.$set(this.lastDataDetails, 'btColorshow', 2)
            }
          }
        }else{
          // this.lastDataDetails.btDataShow = '无数据'
          // this.lastDataDetails.btColorshow = 0
          this.$set(this.lastDataDetails, 'btDataShow', '无数据')
          this.$set(this.lastDataDetails, 'btColorshow', 0)
        }
        // console.log('test lastDataDetails',this.lastDataDetails)

        //心率
        if(data.heartRate != null){
          if(data.heartRate){
            let heartRate = data.heartRate
            this.lastDataDetails.heartRate = heartRate
            if(heartRate < 60){
              // this.lastDataDetails.heartRateDataShow = '未达标'
              // this.lastDataDetails.heartRateColorshow = 1
              this.$set(this.lastDataDetails, 'heartRateDataShow', '未达标')
              this.$set(this.lastDataDetails, 'heartRateColorshow', 1)
            }else if(heartRate > 100){
              // this.lastDataDetails.heartRateDataShow = '未达标'
              // this.lastDataDetails.heartRateColorshow = 3
              this.$set(this.lastDataDetails, 'heartRateDataShow', '未达标')
              this.$set(this.lastDataDetails, 'heartRateColorshow', 3)
            }else{
              // this.lastDataDetails.heartRateDataShow = '达标'
              // this.lastDataDetails.heartRateColorshow = 2
              this.$set(this.lastDataDetails, 'heartRateDataShow', '达标')
              this.$set(this.lastDataDetails, 'heartRateColorshow', 2)
            }
          }
        }else{
          // this.lastDataDetails.heartRateDataShow = '无数据'
          // this.lastDataDetails.heartRateColorshow = 0
          this.$set(this.lastDataDetails, 'heartRateDataShow', '无数据')
          this.$set(this.lastDataDetails, 'heartRateColorshow', 0)
        }
        // console.log('test lastDataDetails',this.lastDataDetails)

        //血氧
        if(data.boValue != null){
          if(data.boValue){
            let boValue = data.boValue
            this.lastDataDetails.boValue = boValue
            if(boValue < 95){
              // this.lastDataDetails.boValueDataShow = '未达标'
              // this.lastDataDetails.boValueColorshow = 1
              this.$set(this.lastDataDetails, 'boValueDataShow', '未达标')
              this.$set(this.lastDataDetails, 'boValueColorshow', 1)
            }else if(boValue > 99){
              // this.lastDataDetails.boValueDataShow = '未达标'
              // this.lastDataDetails.boValueColorshow = 3
              this.$set(this.lastDataDetails, 'boValueDataShow', '未达标')
              this.$set(this.lastDataDetails, 'boValueColorshow', 3)
            }else{
              // this.lastDataDetails.boValueDataShow = '达标'
              // this.lastDataDetails.boValueColorshow = 2
              this.$set(this.lastDataDetails, 'boValueDataShow', '达标')
              this.$set(this.lastDataDetails, 'boValueColorshow', 2)
            }
          }
        } else {
          // this.lastDataDetails.boValueDataShow = '无数据'
          // this.lastDataDetails.boValueColorshow = 0
          this.$set(this.lastDataDetails, 'boValueDataShow', '无数据')
          this.$set(this.lastDataDetails, 'boValueColorshow', 0)
        }
        // console.log('test lastDataDetails',this.lastDataDetails)

        //血压
        if(data.systolic != null && data.diastolic != null){
          if(data.systolic && data.diastolic){
            let systolic = data.systolic
            let diastolic = data.diastolic
            this.lastDataDetails.systolic = systolic
            this.lastDataDetails.diastolic = diastolic
            if(systolic < 90){
              // this.lastDataDetails.bpDataShow = '未达标'
              // this.lastDataDetails.bpColorshow = 1
              // this.lastDataDetails.systolicColorshow = 1
              this.$set(this.lastDataDetails, 'bpDataShow', '未达标')
              this.$set(this.lastDataDetails, 'bpColorshow', 1)
              this.$set(this.lastDataDetails, 'systolicColorshow', 1)
            }else if(systolic > 140){
              // this.lastDataDetails.bpDataShow = '未达标'
              // this.lastDataDetails.bpColorshow = 3
              // this.lastDataDetails.systolicColorshow = 3
              this.$set(this.lastDataDetails, 'bpDataShow', '未达标')
              this.$set(this.lastDataDetails, 'bpColorshow', 3)
              this.$set(this.lastDataDetails, 'systolicColorshow', 3)
            }else{
              // this.lastDataDetails.bpDataShow = '达标'
              // this.lastDataDetails.bpColorshow = 2
              // this.lastDataDetails.systolicColorshow = 2
              this.$set(this.lastDataDetails, 'bpDataShow', '达标')
              this.$set(this.lastDataDetails, 'bpColorshow', 2)
              this.$set(this.lastDataDetails, 'systolicColorshow', 2)
            }
            if(diastolic < 60){
              // this.lastDataDetails.bpDataShow = '未达标'
              // this.lastDataDetails.bpColorshow = 1
              // this.lastDataDetails.diastolicColorshow = 1
              this.$set(this.lastDataDetails, 'bpDataShow', '未达标')
              this.$set(this.lastDataDetails, 'bpColorshow', 1)
              this.$set(this.lastDataDetails, 'diastolicColorshow', 1)
            }else if(diastolic > 90){
              // this.lastDataDetails.bpDataShow = '未达标'
              // this.lastDataDetails.bpColorshow = 3
              // this.lastDataDetails.diastolicColorshow = 3
              this.$set(this.lastDataDetails, 'bpDataShow', '未达标')
              this.$set(this.lastDataDetails, 'bpColorshow', 3)
              this.$set(this.lastDataDetails, 'diastolicColorshow', 3)
            }else{
              // this.lastDataDetails.bpDataShow = '达标'
              // this.lastDataDetails.bpColorshow = 2
              // this.lastDataDetails.diastolicColorshow = 2
              this.$set(this.lastDataDetails, 'bpDataShow', '达标')
              this.$set(this.lastDataDetails, 'bpColorshow', 2)
              this.$set(this.lastDataDetails, 'diastolicColorshow', 2)
            }
          }
        } else {
          // this.lastDataDetails.bpDataShow = '无数据'
          // this.lastDataDetails.bpColorshow = 0
          this.$set(this.lastDataDetails, 'bpDataShow', '无数据')
          this.$set(this.lastDataDetails, 'bpColorshow', 0)
          this.$set(this.lastDataDetails, 'systolicColorshow', 0)
          this.$set(this.lastDataDetails, 'diastolicColorshow', 0)
        }
        // console.log('test lastDataDetails',this.lastDataDetails)

        //血糖
        if(data.bsValue != null){
          if(data.bsValue){
            let bsValue = data.bsValue
            let bsType = data.bsType
            this.lastDataDetails.bsValue = bsValue
            let bstypenum = 0
            // console.log('test bsType')
            // console.log(bsType)
            // console.log(bsType == 1)
            if(bsType == 9){
              bstypenum = 11.1
            }else if(bsType == 1 || bstype == 3 || bstype == 5 || bstype == 7){
              bstypenum = 6.1
            }else if(bstype == 2 || bstype == 4 || bstype == 6 || bstype == 8){
              bstypenum = 7.8
            }
            if(bsValue < 3.9){
              // this.lastDataDetails.bsValueDataShow = '未达标'
              // this.lastDataDetails.bsValueColorshow = 1
              this.$set(this.lastDataDetails, 'bsValueDataShow', '无数据')
              this.$set(this.lastDataDetails, 'bsValueColorshow', 1)
            }else if(bsValue > bstypenum){
              // this.lastDataDetails.bsValueDataShow = '未达标'
              // this.lastDataDetails.bsValueColorshow = 3
              this.$set(this.lastDataDetails, 'bsValueDataShow', '无数据')
              this.$set(this.lastDataDetails, 'bsValueColorshow', 3)
            }else{
              // this.lastDataDetails.bsValueDataShow = '达标'
              // this.lastDataDetails.bsValueColorshow = 2
              this.$set(this.lastDataDetails, 'bsValueDataShow', '达标')
              this.$set(this.lastDataDetails, 'bsValueColorshow', 2)
            }
          }
        } else {
          // this.lastDataDetails.bsValueDataShow = '无数据'
          // this.lastDataDetails.bsValueColorshow = 0
          this.$set(this.lastDataDetails, 'bsValueDataShow', '无数据')
          this.$set(this.lastDataDetails, 'bsValueColorshow', 0)
        }
        // console.log('test lastDataDetails',this.lastDataDetails)
      })
    },
    toDetailPage (data) {
      this.$router.push({
        path: '/externalDynamicDetailPage',
        query: {
          index: data,
          wxUserIdData: this.wxUserIdData,
          idCard: this.idCard,
          lastDataDetails: JSON.stringify(this.lastDataDetails)
        }
      })
    },
  }
}
</script>

<style>
  .bodytest{
    overflow-y: auto;
  }
</style>
